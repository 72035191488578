<template>
  <div>
    <div style="padding: 20px;">
      <logoComponent />
    </div>
    <div class="d-flex align-items-center" style="margin-top: 200px;">
      <CContainer fluid>
        <CModal
          :show.sync="emailSent"
          :no-close-on-backdrop="true"
          title="Email envoyé"
          size="lg"
          color="primary"
        >
        <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
          Votre email de réinitialisation du mot de passe a bien été envoyé 💌
        </p>
        <p>
          <em>N'oubliez pas de vérifiez que l'email n'a pas atteri dans
          vos spams (courrier indésirable).</em>
        </p>

          <template #footer>
            <CButton @click="emailSent = false" color="primary">Fermer</CButton>
          </template>
        </CModal>

        <CRow class="justify-content-center">
          <CCol md="6">
            <CForm v-on:submit.prevent="resetPassword()">
              <h1 class="text-center">Mot de passe oublié ?</h1>
              <CInput
                class="mt-4"
                placeholder="Email"
                type="email"
                label="Saisissez votre adresse email et nous vous enverrons un lien pour réinitialiser votre mot de passe"
                maxlength="249"
                v-model="resetPasswordEmail"
                @input="$v.resetPasswordEmail.$touch()"
                :isValid="$v.resetPasswordEmail.$dirty ? !$v.resetPasswordEmail.$error : null"
              >
                <template #description>
                  <small v-if="errorEmailMessage" class="text-danger"> {{errorEmailMessage}} </small>
                </template>
              </CInput>

              <CRow class="mt-4">
                <CCol class="text-center">
                  <CButton
                    type="submit"
                    :color="resetPasswordButtonStyle" block class="px-4"
                    shape="pill" :disabled="$v.resetPasswordEmail.$invalid || passwordResetingInProcess">
                    <CSpinner size="sm" label="Reset password spinner" v-if="passwordResetingInProcess"></CSpinner> {{ resetPasswordButtonText }}
                  </CButton>
                </CCol>
              </CRow>
              <div class="mt-3">
                  <small>Pas encore de compte ?
                  <router-link to="/pages/inscription">Inscrivez-vous gratuitement</router-link></small>
              </div>
            </CForm>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'

import { AuthAPIService } from '@/api/APIAuth'
import logoComponent from '@/components/logoComponent'

const apiAuth = new AuthAPIService()


export default {
  name: 'PasswordReset',
  components: {
    logoComponent
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {

      resetPasswordEmail: '',
      resetPasswordButtonText: 'Réinitialiser votre mot de passe',
      resetPasswordButtonStyle: 'primary',
      passwordResetingInProcess: false,

      errorEmailMessage: '',
      emailSent: false
    }
  },
  validations: {
    resetPasswordEmail: {
      required,
      email,
      maxLength: maxLength(249),
      minLength: minLength(2)
    }
  },

  methods: {
    resetPassword () {
      this.resetPasswordButtonText = 'Envoi en cours'
      this.resetPasswordButtonStyle = 'secondary'
      this.passwordResetingInProcess = true
      apiAuth.resetPassword(this.resetPasswordEmail)
      .then(() => {
          this.emailSent = true
      })
      .catch((error) => {
        try {
          if (error.response.status == 400) {
            this.errorEmailMessage = "Aucun compte ne semble lié à cet email"
          }
          else {
            this.$store.commit('openGlobalErrorModal')
          }
        }
        catch {
          this.$store.commit('openGlobalErrorModal')
        }
      })
      .finally(() => {
        this.resetPasswordButtonText = 'Réinitialiser votre mot de passe'
        this.resetPasswordButtonStyle = 'primary'
        this.passwordResetingInProcess = false
      })
    },
    goHome () {
      this.$router.push('/')
    },
  }
}
</script>
